.dro {
    width: 100%;
}

.header-sign-in-up {
    margin-right: 40px;
}

.sign {
    border-color: #1e4e70 !important;

}

.signup {
    background-color: #309255 !important;
    color: white !important;
}

.signup:hover {
    background-color: #2d864f !important;
    color: white !important;
}

.signin {
    background-color: #1e4e70 !important;
    color: white !important;
}

.signin:hover {
    background-color: #184464 !important;
    color: white !important;
}

.header {
    background-color: #1e4e70 !important;
}

.divcenter {

    margin: 0 auto !important;
}

@media ( min-width: 1000px) {
    .logo {
        width: 125px !important;
        height: 45px !important;

    }
}

.border-light {
    border-color: white !important;
}

.paddingb {
    padding-bottom: 50px !important;
}

.heightmenu {
    height: 95px !important;
}

.bordermenu {
    border-color: #eefbf3 !important;
}

.textdecoration {
    text-decoration: none;
}

.header-section {
    background-color: #e7f8ee !important;
}