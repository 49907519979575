.drop2 {
    width: 190px;
}

.g {
    margin-right: 139px;
}

.white-color {
    color: white;
}

.black-color {
    color: black;
}

.opacity {
    opacity: 0.9;
}

