.section.section-padding.mt-n10 .spinner-border {
    margin-top: 144px !important;
    position: fixed;
}

.error-content {
    max-width: 100% !important;
    margin-left: auto;
    margin-top: 45px;
}

.error-images {
    margin-top: -84px !important;
    text-align: center !important;

}


.design_page_error_blok {
    background: #e5e5e545;
    margin: 17px;
}

.center {
    text-align: center !important;
}

.divcenter {

    margin: 0 auto !important;
}

.blocpaysuc {
    text-align: center;


}