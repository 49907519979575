.bloc {
    text-align: center;
    color: black;
}

.blocpaysuc {
    text-align: center;
    color: black;
    padding-top: 12%;
}

@media ( max-width: 723px) {
    .blocpaysuc {
        text-align: center;
        color: black;
        padding-top: 22%;
    }
}

.text {
    font-size: 1.3em;
    font-weight: bold;
    color: black;
}

.bold {
    font-weight: bold;
}

.buttons {
    width: 25%;
    background-color: rgb(7, 34, 108);
    color: white;
}

.imgsucc {
    margin-right: 30px;
}

.boutoncolorblu {
    color: #1E4E70 !important;

}

.padding8 {

    padding-top: 50px;
}

.boutonblu {
    background-color: #1E4E70 !important;
}

.divcenter {

    margin: 0 auto !important;
}

/*.divcenter2 {*/

/*    margin: 0 auto !important;*/
/*    width: 800px;*/
/*}*/

@media ( min-width: 1000px) {
    .divcenter2 {
        margin: 0 auto !important;
        width: 800px;
    }
}
