
  
  
  .input-container {
    position: relative;
  }
  
  .currency {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #070707;
  }
