@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;700;800&family=Poppins:wght@400;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.p-sidebar {
  background-color: #1e4e70 !important;
}
.ripple {
  position: relative;
  overflow: hidden;
}
.ripple:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0;
}
.ripple:active:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0.3s ease-out;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e9e9e9;
}

::-webkit-scrollbar-thumb {
  background-color: #3983b8;
  border-radius: 10px;
}

html {
  margin: 0;
  font-family: "Poppins", "Advent Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // user-select: none; /* supported by Chrome and Opera */
  // -webkit-user-select: none; /* Safari */
  // -khtml-user-select: none; /* Konqueror HTML */
  // -moz-user-select: none; /* Firefox */
  // -ms-user-select: none; /* Internet Explorer/Edge */
}

@layer base {
  :root {
    --color-accent-green: #c9fbdc;
    --color-accent-orange: #c9fbdc;
    --color-accent-gray: #f1f1f1;

    --color-primary: #1e4e70;
    --color-primary-50: #f5fafe;
    --color-primary-100: #e9f5fe;
    --color-primary-200: #cceaff;
    --color-primary-300: #abdcff;
    --color-primary-400: #6ec0f9;
    --color-primary-500: #449ddc;
    --color-primary-600: #3983b8;
    --color-primary-700: #296a98;
    --color-primary-800: #1e4e70;
    --color-primary-900: #103753;

    --color-red: #ff6147;
    --color-red-50: #fff8f6;
    --color-red-100: #fff5f3;
    --color-red-200: #ffdcd6;
    --color-red-300: #ffdcd6;
    --color-red-400: #ffc3b9;
    --color-red-500: #ff8e7b;
    --color-red-600: #ff6147;
    --color-red-700: #f93b1c;
    --color-red-800: #c1321b;
    --color-red-900: #94210e;

    --color-green: #0e8b3c;
    --color-green-50: #f1fff6;
    --color-green-100: #ebfff2;
    --color-green-200: #cdfcde;
    --color-green-300: #b5ffd0;
    --color-green-400: #70ffa4;
    --color-green-500: #3fe67d;
    --color-green-600: #1cbf59;
    --color-green-700: #0e8b3c;
    --color-green-800: #0d7132;
    --color-green-900: #055021;

    --color-orange: #985029;
    --color-orange-50: #fff7f2;
    --color-orange-100: #ffece2;
    --color-orange-200: #ffece2;
    --color-orange-300: #ffdac6;
    --color-orange-400: #f9b996;
    --color-orange-500: #ffa574;
    --color-orange-600: #f98444;
    --color-orange-700: #e96924;
    --color-orange-800: #bc5116;
    --color-orange-900: #985029;

    --color-gray: #fefefe;
    --color-gray-50: #fff7f2;
    --color-gray-100: #fcfcfc;
    --color-gray-200: #fbfbfb;
    --color-gray-300: #eeeeee;
    --color-gray-400: #d6d6d6;
    --color-gray-500: #b5b5b5;
    --color-gray-600: #929292;
    --color-gray-700: #707070;
    --color-gray-800: #585656;
    --color-gray-900: #3e3d3d;

    --color-info: #045abc;
    --color-info-50: #f2f8ff;
    --color-info-100: #ebf4ff;
    --color-info-200: #d5e9ff;
    --color-info-300: #aad2ff;
    --color-info-400: #84beff;
    --color-info-500: #63acff;
    --color-info-600: #348cef;
    --color-info-700: #1871d6;
    --color-info-800: #045abc;
    --color-info-900: #0e4079;
  }
}

@media (min-width: 640px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

// @media (min-width: 1024px) { ... }

// @media (min-width: 1280px) { ... }

// @media (min-width: 1536px) { ... }


.dropdown {
  &.focus-within .dropdown-menu {
    opacity: 1;
    transform: translate(0) scale(1);
    visibility: visible;
    z-index: 2000;
  }
  &.focus-without .dropdown-menu {
    opacity: 0;
    transform: translate(0, -0.5rem) scale(0.95);
    visibility: hidden;
    z-index: -1;
  }
}

.p-dropdown-item-group {
  padding: 0 !important;
  border-radius: 8px;
  shape-outside: margin-box;
  opacity: 1;
}