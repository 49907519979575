.colorgray {
    color: #63635a !important;
    text-decoration: none;
}

.colorgraylink {
    color: #63635a !important;
    text-decoration: none;
}

.colorgraylink:hover {
    color: #1E4E70 !important;
    text-decoration: none;
}

.divcenter {

    margin: 0 auto !important;
}

.fontsizewelcome {
    font-size: 1.1em !important;
}