/* .za{
    height:500px;
    background-color:red;
} */
.banneheight {
    height: 60px !important;

}

@media ( max-width: 958px) {
    .banneheight {
        height: 150px !important;

    }
}

.blue {
    color: #1E4E70;
    text-decoration: none;
}

.blue:hover {
    color: #19496b;
    text-decoration: none;
}

.center {
    text-align: center !important;
}